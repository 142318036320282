import '../global.css'

import * as React from 'react'
import BasePage from '../components/basePage'
import { FlexColumn } from '../components/flex'

const AboutPage = () => {
    return (
        <BasePage>
            <FlexColumn>
                <h1>Page Not Found</h1>
                <p>There doesn't seem to be anything here...</p>
            </FlexColumn>
        </BasePage>
    );
}

export default AboutPage
